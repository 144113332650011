<template>
  <div class="accounts-meeting-modal-main-wrapper">
    <div class="account-meeting-text">Meeting</div>

    <div class="clien-person-date-wrapper">
      <div class="client-person-wrapper">
        <div class="client-person-text">
          Client Person<span style="color: red">*</span>
        </div>
        <div>
          <a-select
            v-model:value="clientPersonData"
            mode="tags"
            style="width: 100%"
            placeholder="Select"
            :options="clientPersonOptions"
            @change="handleChange"
            class="export-resume-select-tags"
          >
          </a-select>
        </div>
      </div>
      <div class="date-wrapper">
        <div class="client-person-text">Date</div>
        <div>
          <a-date-picker
            v-model:value="value1"
            class="meeting-date-picker"
            placeholder="Select"
          />
        </div>
      </div>
    </div>

    <div class="department-team-wrapper">
      <div class="client-person-wrapper">
        <div class="client-person-text">Department</div>
        <div>
          <a-select
            v-model:value="value"
            style="width: 100%"
            placeholder="Select"
            :options="departmentsOptions"
            @change="handleChange"
            class="export-resume-select-tags"
          >
          </a-select>
        </div>
      </div>
      <div class="date-wrapper">
        <div class="client-person-text">
          Team<span style="color: red">*</span>
        </div>
        <div>
          <a-select
            v-model:value="value"
            style="width: 100%"
            placeholder="Select"
            :options="options"
            @change="handleChange"
            class="export-resume-select-tags"
          >
          </a-select>
        </div>
      </div>
    </div>

    <div class="sub-team-wrapper">
      <div class="client-person-wrapper">
        <div class="client-person-text">
          Sub Team<span style="color: red">*</span>
        </div>
        <div>
          <a-select
            v-model:value="value"
            style="width: 100%"
            placeholder="Select"
            :options="options"
            @change="handleChange"
            class="export-resume-select-tags"
          >
          </a-select>
        </div>
      </div>
    </div>

    <div class="department-team-wrapper">
      <div class="client-person-wrapper">
        <div class="client-person-text">Meeting Type</div>
        <div>
          <a-select
            v-model:value="value"
            style="width: 100%"
            placeholder="Select"
            :options="options"
            @change="handleChange"
            class="export-resume-select-tags"
          >
          </a-select>
        </div>
      </div>
      <div class="date-wrapper">
        <div class="client-person-text">
          Lead CIC<span style="color: red">*</span>
        </div>
        <div>
          <a-select
            v-model:value="value"
            style="width: 100%"
            placeholder="Select"
            :options="options"
            @change="handleChange"
            class="export-resume-select-tags"
          >
          </a-select>
        </div>
      </div>
    </div>

    <div class="sub-team-wrapper">
      <div class="client-person-wrapper">
        <div class="client-person-text">Attended CIC</div>
        <div>
          <a-select
            v-model:value="value"
            style="width: 100%"
            placeholder="Select"
            :options="options"
            @change="handleChange"
            class="export-resume-select-tags"
          >
          </a-select>
        </div>
      </div>
    </div>

    <div class="avatar-with-remark-wrapper">
      <div style="margin-right: 20px">
        <a-avatar>
          <template #icon>
            <UserOutlined />
          </template>
        </a-avatar>
      </div>
      <div style="width: 100%; margin-bottom: 10px">
        <a-textarea
          v-model:value="value2"
          placeholder="Remark*"
          allow-clear
          class="meeting-modal-remark"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { UserOutlined } from "@ant-design/icons-vue";
import Services from "@/services/apis";
import { ref, onMounted } from "vue";
export default {
  components: { UserOutlined },
  setup() {
    let clientPersonOptions = ref([]);
    let departmentsOptions = ref([]);
    let clientPersonData = ref([]);
    let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
      };
    const clientListing = () => {
      Services.getClientPersonListing(payload)
        .then((response) => {
          const { data } = response;
          console.log("response :>> ", response);
          let tempData = [];
          data[0].client_persons.map((x) => {
            tempData.push({
              value: x.user.id,
              label: x.user.name,
            });
          });
          Object.assign(clientPersonOptions.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    onMounted(() => {
      clientListing();
      Services.getDepartmentsDetail()
        .then((response) => {
          const { data } = response;
          console.log("response :>> ", response);
          let tempdepartment = [];
          data[0].data.map((x) => {
            tempdepartment.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(departmentsOptions.value, tempdepartment);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    });
    console.log("clientPersonOptions :>> ", clientPersonOptions);

    return {
      clientPersonOptions,
      departmentsOptions,
      clientPersonData,
      onMounted,
      clientListing,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/MeetingModal.scss";
</style>