<template>
  <div>
    <div class="show-vacancy-profile-popover-container">
      <div class="popover-title">Vacancy Profile</div>
      <div class="popover-main-section">
        <div class="long-list-section">
          <div v-for="(profile, index) in profileResponse" :key="index">
            <div class="title-count-section">
              <div class="stage-title">{{ profile.name }}</div>
              <div class="stage-count">
                <span>{{ profile.count }}</span>
                <span class="longlist-color dot"> </span>
              </div>
            </div>

            <div class="tags-section">
              <a-tag
                v-for="(vacancy, index) in profile.vacancies"
                :key="index"
                color="#f4db8b"
                class="filter-tags"
              >
                {{ vacancy.name }}
              </a-tag>
            </div>
          </div>
        </div>
        <a-divider />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, computed } from "vue";
const longListTags = reactive([]);
import { useStore } from "vuex";
import Services from "../../../services/apis";

let statusData;

export default {
  components: {},
  setup() {
    let statusData = reactive([]);
    let profileResponse = reactive([]);
    const store = useStore();
    let fetchedData;

    onMounted(() => {
      getProcedureStagesCandidatesProfile();
    });

    function getProcedureStagesCandidatesProfile() {
      fetchedData = computed(
        () =>
          store.getters["candidateStore/getVacancyProfileProcedureStatusData"]
      );
      Object.assign(statusData, fetchedData.value);

      Services.getCandidateShowVacancyProfile(statusData[0].id)
        .then((response) => {
          const { data } = response;

          Object.assign(profileResponse, data);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }

    function selectedCandidates() {
      store.dispatch["candidateStore/candidatesListingSelectedCandidates"];

      getProcedureStagesCandidatesProfile();
    }

    return {
      longListTags,
      selectedCandidates,
      statusData,
      getProcedureStagesCandidatesProfile,
      profileResponse,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/showVacancyProfilePopover.scss";
</style>
