<template>
  <div>
    <a-form :ref="formRef" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="reports-export-common-text">{{ titleResponse }}</div>
      <div class="resume-review-reports">
        <div class="export-title-text">
          Group Name <span class="group-name-mandatory-icon">*</span>
        </div>
        <div class="group-name-field">
          <a-input
            v-model:value="modelRef.groupName"
            style="width: 100%"
            class="dashboard-selecte-input"
            placeholder="Enter Group Name"
          >
          </a-input>
          <div
            @click="addCandidateSubGroup"
            class="group-name-field-branches-icon"
          >
            <BranchesOutlined class="branches-outlined-icon" />
          </div>
        </div>

        <div v-if="modelRef.subGroupInput">
          <a-input
            v-model:value="modelRef.subGroupName"
            v-bind="validateInfos.subGroupName"
            label-in-value
            style="width: 100%"
            :options="options"
            class="dashboard-selecte-input"
            placeholder="Enter SubGroup Name"
          >
          </a-input>
        </div>
        <hr />

        <div>
          <div class="candidates-for-group-text-wrapper">
            <div class="candidates-for-group-text">Candidates for Groups</div>
            <div class="candidates-for-group-text-2">8</div>
          </div>
          <div>
            <div class="candidates-for-group-select-field-wrapper">
              <a-select
                v-model:value="value"
                color="#E5816A"
                class="tags"
                mode="multiple"
                label-in-value
                placeholder="Select users"
                style="width: 100%"
                :filter-option="false"
                :options="candidate_group_options"
                @search="fetchCandidates"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </div>
            <!-- <div class="tag-lists">
            <a-tag
              v-for="(tag, index) in value"
              :key="index"
              class="tags"
              closable
              @close="handleTagsClose(tag)"
              color="#E5816A"
            >
              {{ tag.label }}
            </a-tag>
          </div> -->
          </div>

          <hr />

          <div v-if="modelRef.subGroupInput">
            <div class="candidates-for-group-text-wrapper">
              <div class="candidates-for-group-text">
                Candidates for Sub Groups
              </div>
              <div class="candidates-for-group-text-2">8</div>
            </div>

            <div class="candidates-for-group-select-field-wrapper">
              <a-select
                v-model:value="subGroupValue"
                color="#E5816A"
                class="tags"
                mode="multiple"
                label-in-value
                placeholder="Select users"
                style="width: 100%"
                :filter-option="false"
                :options="candidate_subgroup_options"
                @search="fetchSubGroupCandidates"
              >
              </a-select>
            </div>
          </div>

          <div class="dashboard-cancel-and-export-button-wrapper">
            <div class="dashboard-cancel-button" @click="onHideModal">
              Cancel
            </div>
            <div class="dashboard-export-btn">
              <a-button type="primary" @click="createCandidateGroup"
                >Save</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </a-form>
  </div>
</template>



<script>
import { onMounted, ref, reactive } from "vue";
import { Form } from "ant-design-vue";
import Services from "../../../services/apis";
import { BranchesOutlined } from "@ant-design/icons-vue";

const useForm = Form.useForm;

export default {
  props: ["title"],

  setup(props, context) {
    const formRef = ref();

    let titleResponse = ref(props.title);
    let candidate_group_options = ref([]);
    let candidate_subgroup_options = ref([]);
    let pageCount = 0;
    let fetching = ref(false);
    let value = ref([]);
    let subGroupValue = ref([]);
    let candidateGroupListingData =reactive([]);
    let modelRef = reactive({
      candidateGroups: ref([]),
      groupName: "",
      inputVisible: false,
      tagListsValue: "",
      tags: [],
      subGroupTags: ref([]),
      subGroupInput: false,
      subGroupName: "",
      newSubData: [],
      displayModal: true,
    });

    const viewOptions = [
      { value: "COMPANY", label: "Company" },
      { value: "SITE", label: "Site" },
      { value: "TEAM", label: "Team" },
      { value: "CIC", label: "CIC" },
    ];
    // to add tags to tagList
    const addTags = () => {
      const tagListsValue = modelRef.tagListsValue;
      let tags = modelRef.tags;

      if (tagListsValue && tags.indexOf(tagListsValue) === -1) {
        tags = [...tags, tagListsValue];
      }

      Object.assign(modelRef, {
        tags,
        inputVisible: false,
        tagListsValue: "",
      });
    };

    const handleTagsClose = (removedTag) => {
      const tags = modelRef.tags.filter((tag) => tag !== removedTag);
      modelRef.tags = tags;
    };

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );

    const rulesRef = reactive({
      groupName: [
        {
          required: true,
          message: "Please enter",
        },
      ],
      // repassword: [
      //   {
      //     required: true,
      //     min: 3,
      //     max: 5,
      //     message: "Length should be 5 to 8",
      //     trigger: "change",
      //   },
      // ],
    });

    function fetchCandidates(value) {
      let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
      };
      if (value) {
        payload = {
          q: value,
          pageNo: 0,
          mode: "AUTOCOMPLETE",
        };
      }
      console.log('payload :>> ', value);
      Services.candidatesListing(payload)
        .then((response) => {
          const { data } = response;
          candidate_group_options.value = data[0].candidates.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );

        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }

    function fetchSubGroupCandidates(subGroupValue) {
      let payload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
      };
      if (subGroupValue) {
        payload = {
          q: subGroupValue,
          pageNo: 0,
          mode: "AUTOCOMPLETE",
        };
      }
      Services.candidatesListing(payload)
        .then((response) => {
          const { data } = response;
          
          candidate_subgroup_options.value = data[0].candidates.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }

    const addCandidateSubGroup = () => {
      modelRef.subGroupInput = true;
      let subGroupName = modelRef.subGroupName;
      let subGroupTags = modelRef.subGroupTags;
      let subGroupInput = modelRef.subGroupInput;

      modelRef.subNewGroup = modelRef.subGroupName;
      modelRef.newSubData.push({ subGroupInput, subGroupName });
      console.log("	modelRef.newSubData :>> ", modelRef.newSubData);
      // resetSubGroupField();
    };

    function createCandidateGroup() {
      let payload = {
        name: modelRef.groupName,
        candidates: value.value.map((candidate) => candidate.value),
        subGroups: [
          {
            name: modelRef.subGroupName,
            candidates: subGroupValue.value.map((x) => x.value),
          },
        ],
      };
      Services.candidateCreateGroup(payload)
        .then((response) => {
          const { data } = response;

          getCandidatesGroups();
          onHideModal();
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }

    const resetSubGroupField = () => {
      modelRef.subGroupName = "";
      modelRef.groupName = "";
      value.value = [];
      subGroupValue.value = [];
    };

    const onCloseModal = () => {
      modelRef.displayModal = false;
      modelRef.subGroupInput = false;
    };

    const onSaveModal = () => {
      modelRef.displayModal = false;
    };

    function onHideModal() {
      context.emit("onHideModal", false);
      modelRef.subGroupInput = false;
      resetSubGroupField();
    }

    async function getCandidatesGroups(value) {
      let q = "";
      let payload = {
        q: "",
        pageNo: pageCount,
      };
      if (value) {
        groupListData = [];
        candidateGroupListingData.splice(0);
        payload = { q: value, pageNo: pageCount };
      }

      let groupListData = [];

      await Services.getCandidateGroup(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.data.map((x) => {
              groupListData.push({
                name: x.name,
                noOfCustomers: x.noOfCustomers,
                children: x.subGroups.map((x) => {
                  let tempObject = {
                    name: x.name,
                    noOfCustomers: x.noOfCustomers,
                  };
                  return tempObject;
                }),
              });
            });
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Object.assign(candidateGroupListingData, groupListData);
    }

    onMounted(() => {
      fetchCandidates(value);
      fetchSubGroupCandidates(subGroupValue);
      getCandidatesGroups();
    });

    return {
      candidate_group_options,
      candidate_subgroup_options,
      handleTagsClose,
      addTags,
      modelRef,
      viewOptions,
      addCandidateSubGroup,
      resetSubGroupField,
      onCloseModal,
      onSaveModal,
      onHideModal,
      titleResponse,
      fetching,
      fetchCandidates,
      fetchSubGroupCandidates,
      value,
      subGroupValue,
      createCandidateGroup,
      validate,
      validateInfos,
      resetFields,
      formRef,
      rulesRef,
    };
  },
  components: {
    BranchesOutlined,
  },
};
</script>


<style lang="scss">
@import "../styles/candidateGroupPopover.scss";
</style>