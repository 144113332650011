<template>
  <div>
    <div class="attach-to-vacancy-popover-wrapper">{{ titleResponse }}</div>
    <div class="resume-review-reports">
      <div class="candidates-for-group-text-wrapper">
        Client <span class="group-name-mandatory-icon">*</span>
      </div>
      <div class="group-name-field">
        <a-select
          show-search
          class="export-resume-select-tags"
          v-model:value="modelRef.accountName"
          style="width: 100%"
          placeholder="Select Account"
          :options="company_name_options"
          @search="getCompanyName"
          @change="onSelectAccountChange"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
        ></a-select>
      </div>

      <div>
        <div class="candidates-for-group-text-wrapper">
          Vacancy <span class="group-name-mandatory-icon">*</span>
        </div>
        <div class="candidates-for-group-select-field-wrapper">
          <a-select
            class="export-resume-select-tags"
            v-model:value="modelRef.vacancyId"
            style="width: 100%"
            placeholder="Select"
            :options="vacancy_name_options"
            @search="fetchVacancyListing"
            @change="onSelectVacancyChange"
          ></a-select>
        </div>

        <div class="candidates-for-group-text-wrapper">
          Procedure <span class="group-name-mandatory-icon">*</span>
        </div>

        <div class="candidates-for-group-select-field-wrapper">
          <a-select
            v-model:value="modelRef.procedureStageId"
            mode="multiple"
            class="procedure-stage-select-tags"
            style="width: 100%"
            placeholder="Select"
            :options="procedure_stage_tags_options"
            @change="onProcedureStageChange"
          >
          </a-select>
        </div>

        <div class="dashboard-cancel-and-export-button-wrapper">
          <div
            class="dashboard-cancel-button"
            @click="onHideAttachVacancyModal"
          >
            Cancel
          </div>
          <div class="dashboard-export-btn">
            <a-button type="primary" @click="attachCandidateToVacancyInProfile"
              >Save</a-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, modelRef, rulesRef } from "vue";
import Services from "../../../services/apis";
import { useStore } from "vuex";

export default {
  props: ["title"],

  setup(props, context) {
    let accountId = ref("");
    const store = useStore();

    onMounted(() => {
      fetchProcedureStageData();
      fetchVacancyListing();
      getCompanyName();
    });

    let titleResponse = ref(props.title);
    let currentPageNumber = 0;
    let vacancyData = reactive([]);

    let vacancy_name_options = ref([]);
    let procedure_stage_tags_options = ref([]);
    let company_name_options = ref([]);
    const attachToVacancyVisible = ref(false);

    let modelRef = reactive({
      accountName: ref(""),
      inputVisible: false,
      tagListsValue: "",
      tags: [],
      subGroupInput: false,
      newSubData: [],
      displayModal: true,
      vacancyId: "",
      procedureStageId: "",
      candidateId: "",
    });

    const rulesRef = reactive({
      vacancyId: [
        {
          required: true,
          message: "Please select vacancy",
        },
      ],
      procedureStageId: [
        {
          required: true,
          message: "Please select procedure stage",
        },
      ],
    });
    const viewOptions = [
      { value: "COMPANY", label: "Company" },
      { value: "SITE", label: "Site" },
      { value: "TEAM", label: "Team" },
      { value: "CIC", label: "CIC" },
    ];
    // to add tags to tagList
    const addTags = () => {
      const tagListsValue = modelRef.tagListsValue;
      let tags = modelRef.tags;

      if (tagListsValue && tags.indexOf(tagListsValue) === -1) {
        tags = [...tags, tagListsValue];
      }

      Object.assign(modelRef, {
        tags,
        inputVisible: false,
        tagListsValue: "",
      });
    };

    const onCloseModal = () => {
      modelRef.displayModal = false;
    };

    const onSaveModal = () => {
      modelRef.displayModal = false;
    };

    function onHideAttachVacancyModal() {
      context.emit("onHideAttachVacancyModal", false);
      resetFields();
    }

    const resetFields = () => {
      modelRef.accountName = "";
      modelRef.vacancyId = "";
      modelRef.procedureStageId = "";
    };

    const fetchVacancyListing = async (value) => {
      let payload = {
        q: "",
        pageNo: currentPageNumber,
        mode: "LISTING",
      };
      let listData = vacancyData;
      if (value) {
        payload = { q: value, pageNo: currentPageNumber, mode: "AUTOCOMPLETE" };
      }

      await Services.getVacancyListing(payload)
        .then((response) => {
          const { data } = response;

          let listingData = [];
          let newVacancies = data[0].vacancies.filter(
            (vacancy) => vacancy.account.id === accountId.value
          );
          newVacancies.map((x) => {
            listingData.push({
              value: x.vacancy.id,
              label: x.vacancy.title,
            });
          });
          Object.assign(vacancy_name_options.value, listingData);

          return listingData;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Object.assign(vacancyData, listData);
    };

    const fetchProcedureStageData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PROCEDURE"] };
      Services.getProcedureTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          procedure_stage_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    function onProcedureStageChange() {
      store.dispatch(
        "candidateStore/candidateProfileSelectedProcedureStage",
        modelRef.procedureStageId
      );
    }

    function onSelectVacancyChange() {
      store.dispatch(
        "candidateStore/candidateProfileSelectedVacancy",
        modelRef.vacancyId
      );
    }

    function onSelectAccountChange(value) {
      accountId.value = value;
      store.dispatch(
        "candidateStore/candidateProfileSelectedVacancy",
        modelRef.accountName
      );
      fetchVacancyListing();
    }

    const getCompanyName = (value) => {
      let payload = {};
      console.log("value` ------ :>> ", value);
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getAccountListing(payload)
        .then((response) => {
          const { data } = response;
          let tempData = [];
          data.map((x) => {
            tempData.push({
              value: x.account.id,
              label: x.account.name,
            });
          });
          Object.assign(company_name_options.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    function attachCandidateToVacancyInProfile() {
      context.emit("attachCandidateToVacancyInProfile", false);
      store.dispatch("candidateStore/attachCandidateToVacancyInProfile", false);
      attachToVacancyVisible.value = false;

      resetFields();
    }

    return {
      accountId,
      getCompanyName,
      addTags,
      modelRef,
      rulesRef,
      viewOptions,
      onMounted,
      onCloseModal,
      onSaveModal,
      onHideAttachVacancyModal,
      titleResponse,
      attachToVacancyVisible,
      vacancy_name_options,
      procedure_stage_tags_options,
      fetchVacancyListing,
      fetchProcedureStageData,
      onProcedureStageChange,
      onSelectVacancyChange,
      attachCandidateToVacancyInProfile,
      company_name_options,
      onSelectAccountChange,
      resetFields,
    };
  },
  components: {},
};
</script>

<style lang="scss">
@import "../styles/attachToVacancyPopover.scss";
</style>
