<template>
  <div
    :class="
      actionSelected
        ? 'candidates-right-action-bar-container'
        : 'plannerSelected'
    "
  >
    <div class="search-container" v-if="actionSelected">
      <a-input-search />
    </div>

    <div class="toggle-container">
      <div
        style="
          height: 44px;
          border: 1px solid rgba(255, 255, 255, 0.5)
          box-sizing: border-box;
          border-radius: 15px;
        "
      >
        <span>
          <a-button
            type="link"
            @click="onClickAction"
            :class="actionSelected ? 'selected' : 'unSelected'"
          >
            <span v-if="actionSelected" class="selected-text">Action</span>
            <span v-else></span>
          </a-button>
        </span>
        <span>
          <a-button
            type="link"
            @click="onClickPlanner"
            :class="plannerselected ? 'selected' : 'unSelected'"
          >
            <span v-if="plannerselected" class="selected-text">Planner</span>
            <span v-else></span>
          </a-button>
        </span>
      </div>
    </div>
    <div v-if="actionSelected">
      <div class="menu-container">
        <div class="reports-workflow-text">Workflow</div>
        <a-menu
          mode="inline"
          :style="{ height: '100%', borderRight: 0 }"
          :openKeys="selectedMenuKey"
        >
          <a-sub-menu key="vacancy">
            <template #title>
              <span> Vacancy </span>
            </template>
            <a-menu-item key="create_vacancy" @click="createVacancy"
              >Create Vacancy</a-menu-item
            >
            <!-- <a-menu-item key="attach_candidates">Attach Candidates</a-menu-item>
            <a-menu-item key="change_stage">Change Stage</a-menu-item>
            <a-menu-item key="job_profile_view">Job Profile View</a-menu-item>
            <a-menu-item key="procedure_view">Procedure View</a-menu-item>
            <a-menu-item key="attach_cic">Attach CIC</a-menu-item> -->
          </a-sub-menu>
          <a-sub-menu key="candidate">
            <template #title>
              <span> Candidate </span>
            </template>
            <a-menu-item key="create_candidate" @click="createCandidate"
              >Create Candidate</a-menu-item
            >
            <a-menu-item key="create-group">
              <div>Create Group</div>

              <a-popover
                v-model:visible="visible"
                trigger="click"
                placement="left"
              >
                 <template #content
                    ><CandidateGroupPopover
                      style="width: 460px"
                      :selectedItem="selectedItem"
                      :title="editPopUpTitle"
                      @onHideModal="onHideModal"
                    />
                  </template>
              </a-popover>
            </a-menu-item>
            <!-- <a-menu-item key="merge_resume">Merge Resume</a-menu-item> -->
            <a-menu-item key="attach-to-vacancy">
              <a-popover
                v-model:visible="attachToVacancyVisible"
                trigger="click"
                placement="left"
              >
                <template #content>
                  <AttachToVacancyPopover
                    style="width: 460px"
                    :selectedItem="selectedItem"
                    :title="attachToVacancyTitle"
                    @onHideAttachVacancyModal="onHideAttachVacancyModal"
                    @attachCandidateToVacancyInProfile="
                      attachCandidateToVacancyInProfile
                    "
                  />
                </template>
                <div>Attach to Vacancy</div>
              </a-popover>
            </a-menu-item>
            <a-menu-item
              key="show_vacancy_profile"
              @click="showVacancyProfileModal"
            >
              Show Vacancy Profile
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="sub3">
            <template #title>
              <span> Clients </span>
            </template>
            <a-menu-item key="create_company" @click="createAccount"
              >Create Company</a-menu-item
            >
            <a-menu-item key="create_client_profile" @click="createClientPerson"
              >Create Client Person</a-menu-item
            >
          </a-sub-menu>
          <a-sub-menu key="sub4">
            <template #title>
              <span> Tasks </span>
            </template>
          </a-sub-menu>

          <a-divider class="reports-side-bar-divider" />
          <div class="action-section">
            <div>
              <p>Data</p>
            </div>
            <a-menu-item key="vacancy_delete" class="action-sub-section-text">
              <DeleteOutlined class="vacancy-right-bar-delete-icon" />Delete
            </a-menu-item>
            <div class="action-sub-section">
              <a-menu-item key="vacancy_merge" class="action-sub-section-text"
                >Merge</a-menu-item
              >
              <a-menu-item key="vacancy_export" class="action-sub-section-text"
                >Export</a-menu-item
              >
              <a-menu-item key="save_folder" class="action-sub-section-text"
                >Save Group</a-menu-item
              >
              <a-menu-item
                key="remove_from_folder"
                class="action-sub-section-text"
                >Remove From Group</a-menu-item
              >
              <a-menu-item key="keep_only" class="action-sub-section-text">
                Keep Only in this Group
              </a-menu-item>
              <a-menu-item key="move_to_folder" class="action-sub-section-text">
                Move to Group
              </a-menu-item>
              <a-menu-item key="edit_vacancy_profile" class="edit-folder">
                <a-popover
                  v-model:visible="editVisible"
                  trigger="click"
                  placement="left"
                >
                  <template #content
                    ><CandidateGroupPopover
                      style="width: 460px"
                      :selectedItem="selectedItem"
                      :title="editPopUpTitle"
                      @onHideModal="onHideModal"
                    />
                  </template>
                  <div>Edit Group</div>
                </a-popover>
              </a-menu-item>
              <a-menu-item key="email" class="action-sub-section-text">
                Email
              </a-menu-item>
              <a-menu-item
                key="edit_candidate_profile"
                class="action-sub-section-text"
              >
                Edit Candidate Profile
              </a-menu-item>
              <div class="action-section">
                <a-menu-item
                  key="add_work_details"
                  class="action-sub-section-text"
                >
                  Add Work Details
                </a-menu-item>
                <a-menu-item
                  key="add_education_details"
                  class="action-sub-section-text"
                >
                  Add Education Deatils
                </a-menu-item>
                <a-menu-item key="add_tags" class="action-sub-section-text">
                  Add Tags
                </a-menu-item>
                <a-menu-item
                  key="add_documents"
                  class="action-sub-section-text"
                >
                  Add Documents
                </a-menu-item>
                <a-menu-item key="add_meeting" class="action-sub-section-text">
                  Add Meeting
                </a-menu-item>
              </div>
            </div>
          </div>
        </a-menu>
      </div>
    </div>
    <div v-if="plannerselected" class="right-side-bar-planner-wrapper">
      <div class="date-display-wrapper">
        <div class="today-text">Today</div>
        <div class="date-respone-text">17th Dec, 2020</div>
      </div>
      <div>
        <ul style="padding: 17px; margin: 0px">
          <li>Meeting with Client Person at 10 am</li>
          <li>Meeting with Client Person at 11 am</li>
          <li>Task to be completed</li>
        </ul>
      </div>

      <div class="calendar-wrapper">
        <a-calendar
          v-model:value="value"
          :fullscreen="false"
          @panelChange="onPanelChange"
        >
          <template #headerRender="{ value, onChange }">
            <div style="padding: 10px">
              <div style="margin-bottom: 10px">Custom header</div>
              <a-row type="flex" justify="space-between">
                <a-col>
                  <div>
                    <a-popover
                      v-model:visible="visible"
                      trigger="click"
                      placement="bottom"
                    >
                      <template #content>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Meeting
                        </div>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Task
                        </div>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Reminder
                        </div>
                      </template>
                      <div class="open-popover-plus-icon"><PlusOutlined /></div>
                    </a-popover>
                  </div>
                </a-col>
                <a-col>
                  <a-select
                    size="small"
                    :dropdown-match-select-width="false"
                    class="my-year-select"
                    :value="String(value.year())"
                    @change="
                      (newYear) => {
                        onChange(value.clone().year(newYear));
                      }
                    "
                  >
                    <a-select-option
                      v-for="val in getYears(value)"
                      :key="String(val)"
                      class="year-item"
                    >
                      {{ val }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col>
                  <a-select
                    size="small"
                    :dropdown-match-select-width="false"
                    :value="String(value.month())"
                    @change="
                      (selectedMonth) => {
                        onChange(
                          value.clone().month(parseInt(selectedMonth, 10))
                        );
                      }
                    "
                  >
                    <a-select-option
                      v-for="(val, index) in getMonths(value)"
                      :key="String(index)"
                      class="month-item"
                    >
                      {{ val }}
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
          </template>
        </a-calendar>
      </div>

      <div>
        <div
          style="
            display: flex;
            border-bottom: 1px solid #b3b1b4;
            cursor: pointer;
          "
          @click="OnclickArrow"
        >
          <div class="calendar-text">Calendar</div>
          <div style="margin-left: auto" v-if="hideCalendarOptions">
            <DownOutlined />
          </div>
          <div style="margin-left: auto" v-else>
            <UpOutlined />
          </div>
        </div>
      </div>
      <div style="display: grid; margin-top: 10px" v-if="hideCalendarOptions">
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Meetings</a-checkbox
        >
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Tasks</a-checkbox
        >
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Reminders</a-checkbox
        >
      </div>
    </div>

    <a-divider class="meeting-calendar-divider-section" v-if="actionSelected" />

    <div class="icons-wrapper" v-if="actionSelected">
      <CalendarOutlined
        style="margin-right: 10px; cursor: pointer"
        @click="fetchMeeting()"
      /><MailOutlined style="margin-right: 20px; cursor: pointer" />
    </div>

    <a-modal
      v-model:visible="meetingModal"
      :closable="false"
      @ok="hideModal"
      width="1000px"
    >
      <MeetingModal />
      <template #footer>
        <a-button
          type="link"
          style="
            color: rgb(76, 81, 85);
            font-size: $font_size_12;
            font-family: Poppins;
            font-weight: 500;
          "
          key="back"
          @click="accountMeetingModalCancel"
          >Cancel</a-button
        >
        <a-button
          key="submit"
          style="
            background: RGB(228, 129, 106);
            color: #ffffff;
            border-radius: 15px;
            font-size: $font_size_12;
            font-family: Poppins;
            font-weight: 500;
          "
          @click="accountMeetingModalSave"
          >Save</a-button
        >
      </template>
    </a-modal>

    <a-modal
      class="filter-modal"
      title=""
      width="500px"
      v-model:visible="visibleVacancyProfile"
      :closable="false"
      centered
      footer=""
      destroyOnClose
    >
      <ShowVacancyProfilePopover
        style="width: 460px"
        :selectedItem="selectedItem"
      />
    </a-modal>
  </div>
</template>
<script>
import {
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  CalendarOutlined,
  MailOutlined,
} from "@ant-design/icons-vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import CandidateGroupPopover from "../components/candidateGroupPopover.vue";
import AttachToVacancyPopover from "../components/AttachToVacancyProfile.vue";
import ShowVacancyProfilePopover from "../components/ShowVacancyProfilePopover.vue";
import MeetingModal from "../components/MeetingModal.vue";

let actionSelected = ref(true);
let plannerselected = ref(false);
let hideCalendarOptions = ref(true);
export default {
  components: {
    DeleteOutlined,
    DownOutlined,
    UpOutlined,
    PlusOutlined,
    CandidateGroupPopover,
    AttachToVacancyPopover,
    ShowVacancyProfilePopover,
    CalendarOutlined,
    MailOutlined,
    MeetingModal,
  },
  setup() {
    const router = useRouter();
    const value = ref();
    const visible = ref(false);
    const editVisible = ref(false);
    const attachToVacancyVisible = ref(false);
    const visibleVacancyProfile = ref(false);
    const selectedMenuKey = ref(["candidate"]);
    const meetingModal = ref(false);

    let titleName = ref("Create Group");
    let editPopUpTitle = ref("Edit Group");
    let attachToVacancyTitle = ref("Attach to Vacancy");

    const hide = () => {
      visible.value = false;
    };

    const openMeetingModal = () => {
      meetingModal.value = true;
    };
    const accountMeetingModalCancel = () => {
      meetingModal.value = false;
    };
    const accountMeetingModalSave = () => {
      meetingModal.value = false;
    };

    const createCandidate = () => {
      router.push(`/add-candidate`);
    };
    const onClickAction = () => {
      plannerselected.value = false;
      actionSelected.value = true;
    };

    const onClickPlanner = () => {
      plannerselected.value = true;
      actionSelected.value = false;
    };
    const OnclickArrow = () => {
      hideCalendarOptions.value = !hideCalendarOptions.value;
    };

    function onHideModal(showModal) {
      visible.value = showModal;
      editVisible.value = showModal;
      visibleVacancyProfile.value = showModal;
    }

    function onHideAttachVacancyModal(showAttachToVacancy) {
      attachToVacancyVisible.value = showAttachToVacancy;
    }

    function attachCandidateToVacancyInProfile(show) {
      attachToVacancyVisible.value = show;
    }

    const createVacancy = () => {
      router.push(`/vacancy/create-new-vacancy`);
    };

    const createAccount = () => {
      router.push(`/add-account`);
    };

    const createClientPerson = () => {
      router.push(`/add-client-person`);
    };

    const fetchMeeting = () => {
      router.push("/meetings");
    };

    const showVacancyProfileModal = () => {
      visibleVacancyProfile.value = true;
    };

    const getYears = (value) => {
      const year = value.year();
      const years = [];

      for (let i = year - 10; i < year + 10; i += 1) {
        years.push(i);
      }

      return years;
    };

    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    const getMonths = (value) => {
      const current = value.clone();
      const localeData = value.localeData();
      const months = [];

      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }

      return months;
    };
    return {
      onClickAction,
      actionSelected,
      plannerselected,
      onClickPlanner,
      value,
      onPanelChange,
      OnclickArrow,
      hideCalendarOptions,
      checked: ref(false),
      getYears,
      getMonths,
      visible,
      visibleVacancyProfile,
      hide,
      onHideModal,
      editVisible,
      titleName,
      editPopUpTitle,
      attachToVacancyTitle,
      attachToVacancyVisible,
      onHideAttachVacancyModal,
      attachCandidateToVacancyInProfile,
      showVacancyProfileModal,
      createCandidate,
      selectedMenuKey,
      createVacancy,
      createAccount,
      createClientPerson,
      meetingModal,
      openMeetingModal,
      accountMeetingModalCancel,
      accountMeetingModalSave,
      fetchMeeting,
    };
  },
};
</script>
<style></style>
<style lang="scss">
@import "../styles/rightSideBar.scss";
</style>