<template>
  <div>
    <div class="candidate-listing-container">
      <div style="display: flex">
        <div
          class="tabs-container"
          :style="[rightSideBarVisibility ? ' width : 73%' : ' width : 100%']"
        >
          <a-tabs
            v-model:activeKey="tabKey"
            :animated="false"
            class="listing-tabs"
            @change="onChangeTab(tabKey)"
          >
            <a-tab-pane
              key="candidates"
              tab="Candidates"
              class="tab-pane-container"
            >
              <a-divider />
              <div class="tab-container">
                <div class="search-container">
                  <a-input-search
                    class="header-search-box"
                    placeholder="Search"
                    v-model:value="searchValue"
                    @search="searchCandidate(searchValue)"
                  />
                  <a-button
                    style="
                      height: 44px;
                      border-radius: 13px;
                      background: white;
                      border: none;
                      font-family: 'Poppins Medium';
                    "
                    type="ghost"
                    @click="showFilters"
                  >
                    <FilterOutlined style="font-size: 18px" />
                    Filters
                  </a-button>
                </div>
                <div class="candidate-listing-table">
                  <a-table
                    :columns="listedCandidateColumns"
                    :data-source="candidatesData"
                    :row-selection="rowSelection"
                    :pagination="false"
                    :rowKey="(record) => record.candidateName.id"
                  >
                    <template #candidateName="{ text }">
                      <div style="display: flex">
                        <div class="candidate-image-icon" v-if="text.imageUrl">
                          <img
                            v-if="text"
                            src="{{text}}"
                            alt="Candidate image"
                          />
                        </div>
                        <div class="candidate-image-icon" v-else>
                          <a-avatar>
                            <template #icon><UserOutlined /></template>
                          </a-avatar>
                          &nbsp;
                        </div>
                        <div style="padding-top: 5px">
                          <span
                            class="listing-candidate-name"
                            @click="fetchCandidateProfile(text.id)"
                          >
                            {{ text.name }}
                          </span>
                          <div>
                            <span>
                              <a
                                class="candidate-listing-resume-id"
                                v-if="text.resumeId"
                                >{{ text.resumeId }}</a
                              >
                            </span>
                            <a-button
                              type="link"
                              class="candidate-listing-taxonomy-btn"
                              @click="showTaxonomy(text.id)"
                            >
                              <IdcardOutlined
                                class="candidate-profile-social-media-icon"
                              />
                            </a-button>
                            <a-button
                              type="link"
                              v-if="
                                text.socialAccounts.linkedIn &&
                                Object.keys(text.socialAccounts.linkedIn)
                                  .length !== 0 && text.socialAccounts.linkedIn.link !== ''
                              "
                              style=""
                              class="candidate-listing-social-media-btn"
                              href="text.socialAccounts.linkedIn.link"
                            >
                              <LinkedinFilled
                                circle
                                class="candidate-profile-social-media-icon"
                              />
                            </a-button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #rating="{ text }">
                      <a-rate
                        :value="parseFloat(text.rating / 4)"
                        disabled
                        allowHalf
                      />
                    </template>
                    <template #ctc="{ text }">
                      <div class="candidate-text-color-light" v-if="text">
                        {{ text }}
                      </div>
                      <div class="candidate-text-color-light" v-else>0</div>
                    </template>
                    <template #city="{ text }">
                      <div class="candidate-text-color-light" v-if="text">
                        {{ text }}
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template #totalWorkExp="{ text }">
                      <span v-if="text">
                        {{ text }}
                      </span>
                      <span v-else>0</span>
                    </template>
                    <template #workExperience="{ text }">
                      <span v-if="text">{{ text }}</span>
                      <span v-else>0</span>
                    </template>
                    <template #call="{ text }">
                      <span v-if="text">{{ text }}</span>
                      <span v-else> - </span>
                    </template>
                    <template #documentsCount="{ text }">
                      <span v-if="text">{{ text }}</span>
                      <span v-else>0</span>
                    </template>
                    <template #cic="{ text }">
                      <div v-if="text">
                        {{ text }}
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template #industry="{ text }">
                      <div v-if="text">
                        {{ text }}
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template #customTitle>
                      <a-button
                        @click="showColumnManager"
                        type="link"
                        style="color: #4c5155"
                      >
                        <MenuOutlined />
                      </a-button>
                    </template>
                  </a-table>
                </div>
                <div v-if="candidatesData.length >= 10" class="view-more-btn">
                  <a-button type="link" @click="fetchMoreCandidates()">
                    <template #icon>
                      <PlusCircleOutlined />
                      View More
                    </template>
                  </a-button>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane
              key="candidate-groups"
              tab="Candidate Groups"
              class="tab-pane-container"
            >
              <a-divider />
              <div class="tab-container">
                <div class="search-container">
                  <a-input-search
                    class="header-search-box"
                    placeholder="Search"
                    v-model:value="groupSearchValue"
                    @search="searchCandidateGroup(groupSearchValue)"
                  />
                </div>

                <div class="candidate-group-listing-table">
                  <div class="candidate-groups-radio">
                    <div class="candidates-all-groups">
                      <TeamOutlined />All Groups
                    </div>
                    <div class="candidates-all-groups">
                      <TeamOutlined />Saved Groups
                    </div>
                  </div>

                  <!-- Candidates Group Table  -->
                  <a-table
                    :columns="candidateGroupListingColumns"
                    :data-source="candidateGroupListingData"
                    :row-selection="treeRowSelection"
                    :pagination="false"
                    :rowKey="(record) => record.noOfCustomers.groupId"
                  >
                    <template #noOfCustomers="{ text }">
                      <span @click="groupNumber(text.groupId, text.type)">{{
                        text.candidates
                      }}</span>
                    </template>
                  </a-table>
                </div>
                <div
                  v-if="candidateGroupListingData.length >= 10"
                  class="view-more-btn"
                >
                  <a-button
                    type="link"
                    @click="fetchMoreCandidateGroups(groupSearchValue)"
                  >
                    <template #icon>
                      <PlusCircleOutlined />
                      View More
                    </template>
                  </a-button>
                </div>
              </div>
            </a-tab-pane>
            <!-- Candidate Filter Modal -->
          </a-tabs>
          <!-- Filter Modal -->
          <a-modal
            class="filter-modal"
            title=""
            v-model:visible="filterModalVisibility"
            :closable="false"
            centered
            width="1200px"
          >
            <div>
              <div style="width=80%">
                <candidateFilter
                  @closeFilter="filterModalCancel"
                  @filterModalOk="filterModalOk"
                />
              </div>
            </div>
            <template #footer>
              <a-button style="margin: 0.5rem 0"> </a-button>
            </template>
          </a-modal>

          <!-- Candiddate Group Filter  -->

          <!-- Manage Columns Modal -->
          <a-modal
            class="column-modal"
            title=""
            v-model:visible="manageColumnsModalVisibility"
            :closable="false"
            centered
            @ok="columnManagerModalOk"
            width="500px"
          >
            <div>
              <div style="width=80%">
                <manageColumnComponent
                  :masterList="columnData"
                  @updateColumns="updateColumns"
                />
              </div>
            </div>
            <template #footer>
              <a-button> </a-button>
            </template>
          </a-modal>
          <!-- Taxonomy Widget MModal -->
          <a-modal
            class="filter-modal"
            title=""
            width="500px"
            v-model:visible="taxonomyModalVisibility"
            :closable="false"
            centered
            footer=""
            destroyOnClose
          >
            <TaxonomyWidget :id="taxonomy_id" />
          </a-modal>
        </div>
        <div>
          <LeftCircleTwoTone
            v-if="!rightSideBarVisibility"
            @click="showRightSideBar"
            style="color: blue; font-size: 1.5rem"
          />
          <RightCircleTwoTone
            v-if="rightSideBarVisibility"
            @click="showRightSideBar"
            style="
              font-size: 1.5rem;
              position: fixed;
              z-index: 9;
              transform: translateX(-10px);
            "
          />
        </div>
        <div v-if="rightSideBarVisibility" class="action-bar">
          <RightSideBar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import candidateFilter from "./candidateFilter.vue";
import TaxonomyWidget from "./widget.vue";
import manageColumnComponent from "../../common/components/manageColumns.vue";
import RightSideBar from "../components/rightSideBar.vue";
import {
  ref,
  onUnmounted,
  onMounted,
  getCurrentInstance,
  defineComponent,
  reactive,
} from "vue";
import Services from "../../../services/apis";
import {
  FilterOutlined,
  UserOutlined,
  MenuOutlined,
  LinkedinFilled,
  PlusCircleOutlined,
  IdcardOutlined,
  TeamOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
} from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Utils from "../../../utils";

const candidateGroupListingColumns = [
  {
    title: "Groups",
    dataIndex: "name",
    key: "name",
    slots: { customRender: "name" },
  },
  {
    title: "No. of Candidates",
    dataIndex: "noOfCustomers",
    key: "noOfCustomers",
    slots: { customRender: "noOfCustomers" },
    align: "center",
  },
];
const candidateGroupListingData = reactive([]);

const treeRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

let candidatesData = reactive([]);
let candidatesGroupData = reactive([]);

let columnData = reactive([
  {
    id: "personal_details",
    constants: "PERSONAL_DETAILS",
    isVisible: true,
    name: "Personal Details",
    children: [
      {
        name: "Name",
        id: "name",
        isSelecetd: false,
      },
      {
        name: "Email",
        id: "email",
        isSelecetd: false,
      },
      {
        name: "Call",
        id: "call",
        isSelecetd: true,
      },
      {
        name: "City",
        id: "city",
        isSelecetd: true,
      },
      {
        name: "Resume ID",
        id: "resume_id",
        isSelecetd: false,
      },
      {
        name: "LinkedIn",
        id: "linkedIn",
        isSelecetd: false,
      },
      {
        name: "Rating",
        id: "rating",
        isSelecetd: true,
      },
      {
        name: "Review",
        id: "review",
        isSelecetd: false,
      },
      {
        name: "Gender",
        id: "gender",
        isSelecetd: false,
      },
      {
        name: "Residential Num.",
        id: "residential_num",
        isSelecetd: false,
      },
    ],
  },
  {
    id: "work_details",
    constants: "WORK_DETAILS",
    isVisible: true,
    name: "Work Details",
    children: [
      {
        name: "Work Exp",
        id: "workExperience",
        isSelecetd: true,
      },
      {
        name: "T.W.Exp",
        id: "totalWorkExp",
        isSelecetd: true,
      },
      {
        name: "Level",
        id: "level",
        isSelecetd: false,
      },
      {
        name: "Co. Designation",
        id: "designation",
        isSelecetd: false,
      },
      {
        name: "Reports To",
        id: "reports_to",
        isSelecetd: false,
      },
      {
        name: "Compensation",
        id: "ctc",
        ellipsis: true,
        isSelecetd: true,
      },
    ],
  },
  {
    id: "taxonomy",
    constants: "TAXONOMY",
    isVisible: true,
    name: "Taxonomy",
    children: [
      {
        name: "Industry",
        id: "industry",
        isSelecetd: false,
      },
      {
        name: "Sector",
        id: "sector",
        isSelecetd: false,
      },
      {
        name: "Practice Area",
        id: "practice_area",
        isSelecetd: false,
      },
      {
        name: "SPA",
        id: "spa",
        isSelecetd: false,
      },
      {
        name: "Function",
        id: "function",
        isSelecetd: false,
      },
      {
        name: "SF",
        id: "sf",
        isSelecetd: false,
      },
      {
        name: "SSF Tag",
        id: "ssf",
        isSelecetd: false,
      },
    ],
  },
  {
    id: "education",
    constants: "EDUCATION",
    isVisible: true,
    name: "Education",
    children: [
      {
        name: "Education",
        id: "education",
        isSelecetd: false,
      },
      {
        name: "Qual. Rem",
        id: "qual_rem",
        isSelecetd: false,
      },
      {
        name: "Doc. Nos.",
        id: "doc_nos",
        isSelecetd: false,
      },
    ],
  },
  {
    id: "other",
    constants: "OTHER",
    isVisible: true,
    name: "Other",
    children: [
      {
        name: "CIC Key",
        id: "cic",
        isSelecetd: true,
      },
      {
        name: "Create Date",
        id: "create_date",
        isSelecetd: false,
      },
      {
        name: "UL",
        id: "ul",
        isSelecetd: false,
      },
    ],
  },
  {
    id: "default",
    constants: "DEFAULT",
    isVisible: false,
    name: "Default",
    children: [
      {
        name: "Taxonomy",
        id: "taxonomy",
        isSelecetd: true,
      },
      {
        name: "Candidate",
        id: "candidateName",
        isSelecetd: true,
      },
      {
        name: "Image",
        id: "imageUrl",
        isSelecetd: true,
      },
      {
        name: "Docs",
        id: "documentsCount",
        isSelecetd: true,
      },
    ],
  },
]);

export default defineComponent({
  components: {
    FilterOutlined,
    UserOutlined,
    MenuOutlined,
    LinkedinFilled,
    IdcardOutlined,
    PlusCircleOutlined,
    candidateFilter,
    TaxonomyWidget,
    manageColumnComponent,
    RightSideBar,
    TeamOutlined,
    LeftCircleTwoTone,
    RightCircleTwoTone,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let taxonomy_id = ref("");
    let rightSideBarVisibility = ref(false);
    const showRightSideBar = () => {
      rightSideBarVisibility.value = !rightSideBarVisibility.value;
    };
    let listedCandidateColumns = reactive([
      {
        title: "Candidate",
        default: true,
        dataIndex: "candidateName",
        key: "candidateName",
        align: "left",
        slots: { customRender: "candidateName" },
        width: 175,
      },
      {
        title: "Rating",
        default: true,
        dataIndex: "rating",
        key: "rating",
        align: "left",
        width: 120,
        slots: { customRender: "rating" },
      },
      {
        width: 125,
        title: "Compensation",
        default: true,
        dataIndex: "ctc",
        key: "ctc",
        ellipsis: true,
        slots: { customRender: "ctc" },
        align: "center",
      },
      {
        title: "City",
        default: true,
        dataIndex: "city",
        key: "city",
        slots: { customRender: "city" },
        align: "center",
      },
      {
        title: "T.W.Exp",
        default: true,
        dataIndex: "totalWorkExp",
        key: "totalWorkExp",
        slots: { customRender: "totalWorkExp" },
      },
      {
        title: "Work Exp.",
        default: true,
        dataIndex: "workExperience",
        key: "workExperience",
        slots: { customRender: "workExperience" },
        width: 125,
        align: "center",
      },
      {
        title: "Call",
        default: true,
        dataIndex: "call",
        key: "call",
        slots: { customRender: "call" },
        align: "center",
      },

      {
        title: "CIC Key",
        default: true,
        dataIndex: "cic",
        key: "cic",
        slots: { customRender: "cic" },
        align: "center",
      },

      {
        title: "Docs.",
        default: true,
        dataIndex: "documentsCount",
        key: "documentsCount",
        slots: { customRender: "documentsCount" },
        align: "center",
      },
      {
        colSpan: 1,
        width: 0,
        key: "manageColumns",
        slots: { title: "customTitle" },
      },
    ]);

    const candidateListingColumns = reactive([
      {
        title: "Candidate",
        default: true,
        dataIndex: "candidateName",
        key: "candidateName",
        align: "left",
        slots: { customRender: "candidateName" },
        width: 175,
      },
      {
        title: "Rating",
        default: true,
        dataIndex: "rating",
        key: "rating",
        align: "left",
        // width: 120,
        slots: { customRender: "rating" },
      },
      {
        width: 125,
        title: "Compensation",
        default: true,
        dataIndex: "ctc",
        key: "ctc",
        slots: { customRender: "ctc" },
        align: "center",
      },
      {
        title: "City",
        default: true,
        dataIndex: "city",
        key: "city",
        slots: { customRender: "city" },
        align: "center",
        width: 40,
      },
      {
        title: "T.W.Exp",
        default: true,
        dataIndex: "totalWorkExp",
        key: "totalWorkExp",
        slots: { customRender: "totalWorkExp" },
      },
      {
        title: "Work Exp.",
        default: true,
        dataIndex: "workExperience",
        key: "workExperience",
        slots: { customRender: "workExperience" },
        width: 125,
        align: "center",
      },
      {
        title: "Call",
        default: true,
        dataIndex: "call",
        key: "call",
        slots: { customRender: "call" },
        align: "center",
        width: 40,
      },
      {
        title: "CIC Key",
        default: true,
        dataIndex: "cic",
        key: "cic",
        slots: { customRender: "cic" },
        width: 50,
      },

      {
        colSpan: 1,
        width: 0,
        key: "manageColumns",
        slots: { title: "customTitle" },
      },
    ]);

    const { ctx: _this } = getCurrentInstance();

    let ratingValue = ref(2);

    const filterModalVisibility = ref(false);

    const taxonomyModalVisibility = ref(false);

    let manageColumnsModalVisibility = ref(false);

    const radioValue = ref(1);

    let listDataObj = {};

    let tabKey = ref("candidates");

    // let groupId = ref("");

    let subGroupId = ref("");

    // Search Value

    const searchValue = ref("");
    const groupSearchValue = ref("");
    //

    const showFilters = () => {
      filterModalVisibility.value = true;
    };

    const showTaxonomy = (id) => {
      taxonomy_id.value = id;
      taxonomyModalVisibility.value = true;
    };

    const filterModalOk = (payload, value) => {
      pageCount = 0;
      fetchCandidates(payload);
      filterModalVisibility.value = value;
      listDataObj = Utils.deepCopyObject(payload);

      console.log("listDataObj :>> ", listDataObj);
    };

    const filterModalCancel = (value) => {
      filterModalVisibility.value = value;
    };

    const showColumnManager = () => {
      manageColumnsModalVisibility.value = true;
    };

    const columnManagerModalOk = (e) => {
      manageColumnsModalVisibility.value = false;
    };

    let pageCount = 0;

    //Row Selection function
    let nameLists = [];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let selectedCandidates = [];
        selectedRows.map((x) => {
          let candidateObject = {
            id: x.candidateName.id,
            name: x.candidateName.name,
          };
          selectedCandidates.push(candidateObject);
        });
        addSelectedCandidates(selectedCandidates);

        getProcedureStagesCandidatesProfile();

        selectedCandidates = [];
      },
    };

    const fetchMoreCandidates = () => {
      pageCount++;
      if (searchValue.value) {
        fetchCandidates(searchValue.value);
      } else if (listDataObj) {
        fetchCandidates(listDataObj);
      } else {
        fetchCandidates();
      }
    };

    const fetchMoreCandidateGroups = (value) => {
      pageCount++;
      getCandidatesGroups(value);
    };

    const searchCandidate = (value) => {
      pageCount = 0;
      fetchCandidates(value,'','');
    };

    const searchCandidateGroup = (value) => {
      pageCount = 0;
      getCandidatesGroups(value);
    };

    const fetchCandidateProfile = (id) => {
      router.push(`/candidates/${id}/detail`);
    };

    const addSelectedCandidates = (selectedCandidates) => {
      store.dispatch(
        "candidateStore/candidatesListingSelectedCandidates",
        selectedCandidates
      );
    };

    function getProcedureStagesCandidatesProfile(selectedCandidates) {
      store.dispatch(
        "candidateStore/getProcedureStagesCandidatesProfile",
        selectedCandidates
      );
    }

    const fetchCandidates = async (value, groupId, type) => {
      let listData = [];
      let q = "";
      let payload = {};

      if (type === "GROUP") {
        payload = {
          groups: groupId,
          subGroups: "",
          q: "",
          pageNo: pageCount,
          mode: "LISTING",
        };
      }
      if (type === "SUB_GROUP") {
        payload = {
          groups: "",
          subGroups: groupId,
          q: "",
          pageNo: pageCount,
          mode: "LISTING",
        };
      }

      if (pageCount > 0) {
        listData = candidatesData;
      } else {
        candidatesData.splice(0);
      }
      if ((typeof value === "string", groupId === "", type === "")) {
        listData = candidatesData;
        payload = { q: value, pageNo: pageCount, mode: "LISTING" };
      } else if (typeof value === "object") {
        if (candidatesData.length === 0) {
          listData = candidatesData;
        }
        payload = {
          ...value,
          pageNo: pageCount,
          mode: "LISTING",
        };
        console.log("payload :>> ", payload);
      } else {
        candidatesData.splice(0);
      }

      await Services.candidatesListing(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.length &&
            data[0].candidates.map((x) => {
              listData.push({
                imageUrl: x.imageUrl,
                candidateName: {
                  name: x.name,
                  resumeId: x.resumeId,
                  experience: x.experience,
                  socialAccounts: x.socialAccounts,
                  education: x.education,
                  subPracticeArea: x.subPracticeArea,
                  practiceArea: x.practiceArea,
                  designation: "",
                  id: x.id,
                },
                rating: x,
                city: "",
                notesCount: x.notesCount,
                totalWorkExp: x.totalWorkExp,
                workExperience: "",
                documentsCount: x.documentsCount,
                ctc: x.ctc,
                cic: x.cics.length ? x.cics[0].name : "-",
                call: "",
                status: x.status,
              });
            });

          if (data[0].candidates.length === 0) {
            candidatesData.splice(0);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Object.assign(candidatesData, listData);
    };

    function updateColumns(masterList, status) {
      columnData = masterList;
      manageColumnsModalVisibility.value = status;
      let newColumnData = [];
      candidateListingColumns.map((column, ind) => {
        columnData.map((parent) => {
          let filterData = parent.children.filter(
            (obj) => obj.isSelecetd === true && obj.id === column.dataIndex
          );
          if (filterData.length > 0) {
            newColumnData.push(column);
          }
        });
      });
      Object.assign(listedCandidateColumns, newColumnData);
    }

    const onChangeTab = (tabKey) => {
      if (tabKey === "candidates") {
        fetchCandidates();
      }

      if (tabKey === "candidate-groups") {
        getCandidatesGroups();
      }
    };

    async function getCandidatesGroups(value) {
      let q = "";
      let payload = {
        q: "",
        pageNo: pageCount,
      };
      if (value) {
        groupListData = [];
        candidateGroupListingData.splice(0);
        payload = { q: value, pageNo: pageCount };
      }
      let groupListData = [];

      await Services.getCandidateGroup(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.data.map((x) => {
              groupListData.push({
                name: x.name,
                noOfCustomers: {
                  groupId: x.id,
                  candidates: x.noOfCustomers,
                  type: x.type,
                },
                children: x.subGroups.map((x) => {
                  let tempObject = {
                    name: x.name,
                    noOfCustomers: {
                      groupId: x.id,
                      candidates: x.noOfCustomers,
                      type: x.type,
                    },
                  };
                  return tempObject;
                }),
              });
              console.log("groupListData :>> ", groupListData);
            });
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Object.assign(candidateGroupListingData, groupListData);
    }

    const groupNumber = (groupId, type) => {
      console.log("id Group:>> ", groupId);
      console.log("type :>> ", type);
      // console.log('value :>> ', value);

      tabKey.value = "candidates";

      if ((groupId, type === "GROUP")) {
        fetchCandidates("", groupId, type);
       store.dispatch("candidateStore/getGroupIds", groupId);
      }

      if ((groupId, type === "SUB_GROUP")) {
        fetchCandidates("", groupId, type);
        store.dispatch("candidateStore/getCandidateSubGroupId", groupId);
        console.log("subGroupId :>> ", groupId);
      }
    };

    onMounted(() => {
      fetchCandidates();
      let newColumnData = candidateListingColumns.filter((col) => col.default);
      Object.assign(listedCandidateColumns, newColumnData);
    });
    onUnmounted(() => {
      candidatesData = reactive([]);
    });
    return {
      ratingValue,
      rightSideBarVisibility,
      showRightSideBar,
      radioValue,
      showFilters,
      showTaxonomy,
      taxonomy_id,
      onUnmounted,
      filterModalOk,
      filterModalCancel,
      filterModalVisibility,
      taxonomyModalVisibility,
      showColumnManager,
      manageColumnsModalVisibility,
      columnManagerModalOk,
      pageCount,
      candidatesData,
      fetchCandidates,
      fetchMoreCandidates,
      searchCandidate,
      fetchCandidateProfile,
      updateColumns,
      tabKey,
      candidateListingColumns,
      listedCandidateColumns,
      rowSelection,
      treeRowSelection,
      candidateGroupListingColumns,
      candidateGroupListingData,
      columnData,
      addSelectedCandidates,
      getProcedureStagesCandidatesProfile,
      getCandidatesGroups,
      candidatesGroupData,
      onChangeTab,
      searchValue,
      fetchMoreCandidateGroups,
      groupSearchValue,
      searchCandidateGroup,
      listDataObj,
      groupNumber,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/listCandidates.scss";
</style>
